import { ScenarioInfoService } from 'prosumer-app/+scenario/services';
import { OptimizationCustomOptionStore } from 'prosumer-app/stores/custom-option';
import { EGCEmissionStore } from 'prosumer-app/stores/egc-emission';
import { EnergyGridConnectionStore } from 'prosumer-app/stores/energy-grid-connection';
import { EnergyVectorStore } from 'prosumer-app/stores/energy-vector';
import { EquipmentStore } from 'prosumer-app/stores/equipment';
import { FuelStore } from 'prosumer-app/stores/fuel';
import { LineStore } from 'prosumer-app/stores/line';
import { LoadStore } from 'prosumer-app/stores/load';
import { MarketLimitStore } from 'prosumer-app/stores/market-limit';
import { MeterCostAndBoundStore } from 'prosumer-app/stores/meter-cost-and-bound/meter-cost-and-bound.store';
import { MeterDefinitionStore } from 'prosumer-app/stores/meter-definition/meter-definition.store';
import { MeterTechnologiesAndMarketsStore } from 'prosumer-app/stores/meter-technologies-and-markets/meter-technologies-and-markets.store';
import { MobilityRouteStore } from 'prosumer-app/stores/mobility-route';
import { MobilityStationStore } from 'prosumer-app/stores/mobility-station';
import { NettingStore } from 'prosumer-app/stores/netting';
import { NodeStore } from 'prosumer-app/stores/node';
import { PowerNeededStore } from 'prosumer-app/stores/power-needed';
import { RegulationStore } from 'prosumer-app/stores/regulation';
import { ReserveEquipmentStore } from 'prosumer-app/stores/reserve-equipment';
import { ReserveNetworkStore } from 'prosumer-app/stores/reserve-network';
import { ScenarioDetailType } from 'prosumer-app/stores/scenario-detail';
import { ScenarioVariationStore } from 'prosumer-app/stores/scenario-variation';
import { ShortCircuitEquipmentStore } from 'prosumer-app/stores/short-circuit-equipment';
import { SpinningReserveActivationStore } from 'prosumer-app/stores/spinning-reserve-activation';
import { SpinningReserveMarginStore } from 'prosumer-app/stores/spinning-reserve-margin';
import { SpinningReserveMarketStore } from 'prosumer-app/stores/spinning-reserve-market';
import { TaxAndSubsidyStore } from 'prosumer-app/stores/tax-and-subsidy';
import { TimePartitionStore } from 'prosumer-app/stores/time-partition';
import { VehiclesDispatchStore } from 'prosumer-app/stores/vehicles-dispatch';
import { combineLatest, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { Injectable } from '@angular/core';

type GetterFn = () => Observable<unknown>;

@Injectable({
  providedIn: 'root',
})
export class DetailGettingDelegator {
  constructor(
    private readonly emissions: EGCEmissionStore,
    private readonly egcs: EnergyGridConnectionStore,
    private readonly vectors: EnergyVectorStore,
    private readonly fuels: FuelStore,
    private readonly loads: LoadStore,
    private readonly equipments: EquipmentStore,
    private readonly lines: LineStore,
    private readonly limits: MarketLimitStore,
    private readonly routes: MobilityRouteStore,
    private readonly stations: MobilityStationStore,
    private readonly nettings: NettingStore,
    private readonly nodes: NodeStore,
    private readonly powers: PowerNeededStore,
    private readonly regulations: RegulationStore,
    private readonly taxubs: TaxAndSubsidyStore,
    private readonly variations: ScenarioVariationStore,
    private readonly dispatches: VehiclesDispatchStore,
    private readonly info: ScenarioInfoService,
    private readonly scEquipments: ShortCircuitEquipmentStore,
    private readonly srMargins: SpinningReserveMarginStore,
    private readonly srActivations: SpinningReserveActivationStore,
    private readonly reserveEquipments: ReserveEquipmentStore,
    private readonly reserveMarkets: SpinningReserveMarketStore,
    private readonly reserveNetworks: ReserveNetworkStore,
    private readonly options: OptimizationCustomOptionStore,
    private readonly timePartition: TimePartitionStore,
    private readonly meterDefinition: MeterDefinitionStore,
    private readonly meterCostAndBound: MeterCostAndBoundStore,
    private readonly meterTechAndMarkets: MeterTechnologiesAndMarketsStore,
  ) {}

  getScenario(): void {
    this.info.getScenario().pipe(take(1)).subscribe();
  }

  delegateFor(types: ScenarioDetailType[]): void {
    this.doAll(this.typesToGetterFns(types).map((fn) => fn()));
  }

  private doAll(observables: Observable<unknown>[]): void {
    combineLatest(observables).pipe(take(1)).subscribe();
  }

  private typesToGetterFns(types: ScenarioDetailType[]): GetterFn[] {
    return types.map((type) => this.deduceGetterFn(type));
  }

  private deduceGetterFn(type: ScenarioDetailType): GetterFn {
    return this.detailGetterMap[type] || (() => of({}));
  }

  private get detailGetterMap(): Partial<Record<ScenarioDetailType, GetterFn>> {
    return {
      [ScenarioDetailType.egcEmission]: () => this.emissions.improvedGetAll(),
      [ScenarioDetailType.energyGridConnection]: () =>
        this.egcs.improvedGetAll(),
      [ScenarioDetailType.energyVector]: () =>
        this.vectors.getEnergyVectors({}),
      [ScenarioDetailType.fuel]: () => this.fuels.improvedGetAll(),
      [ScenarioDetailType.load]: () => this.loads.getLoads({}),
      [ScenarioDetailType.equipment]: () => this.equipments.improvedGetAll(),
      [ScenarioDetailType.line]: () => this.lines.getLines({}),
      [ScenarioDetailType.marketLimit]: () => this.limits.improvedGetAll(),
      [ScenarioDetailType.mobilityRoute]: () => this.routes.improvedGetAll(),
      [ScenarioDetailType.mobilityStation]: () =>
        this.stations.improvedGetAll(),
      [ScenarioDetailType.netting]: () => this.nettings.improvedGetAll(),
      [ScenarioDetailType.node]: () => this.nodes.improvedGetAll(),
      [ScenarioDetailType.powerNeeded]: () => this.powers.improvedGetAll(),
      [ScenarioDetailType.regulation]: () => this.regulations.improvedGetAll(),
      [ScenarioDetailType.taxAndSubsidy]: () => this.taxubs.improvedGetAll(),
      [ScenarioDetailType.variation]: () => this.variations.getVariations({}),
      [ScenarioDetailType.vehiclesDispatch]: () =>
        this.dispatches.improvedGetAll(),
      [ScenarioDetailType.scEquipment]: () =>
        this.scEquipments.improvedGetAll(),
      [ScenarioDetailType.srMargin]: () => this.srMargins.improvedGetAll(),
      [ScenarioDetailType.srActivation]: () =>
        this.srActivations.improvedGetAll(),
      [ScenarioDetailType.reserveEquipment]: () =>
        this.reserveEquipments.improvedGetAll(),
      [ScenarioDetailType.reserveMarket]: () =>
        this.reserveMarkets.improvedGetAll(),
      [ScenarioDetailType.reserveNetwork]: () =>
        this.reserveNetworks.improvedGetAll(),
      [ScenarioDetailType.customOption]: () => this.options.improvedGetAll(),
      [ScenarioDetailType.timePartition]: () =>
        this.timePartition.improvedGetAll(),
      [ScenarioDetailType.meterDefinition]: () =>
        this.meterDefinition.improvedGetAll(),
      [ScenarioDetailType.meterCostAndBound]: () =>
        this.meterCostAndBound.improvedGetAll(),
      [ScenarioDetailType.meterTechnologiesAndMarkets]: () =>
        this.meterTechAndMarkets.improvedGetAll(),
    };
  }
}
