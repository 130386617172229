import { ScenarioWizardStep } from 'prosumer-app/+scenario/services/scenario-completion';
import { Coerce } from 'prosumer-app/core/utils';
import { UserFacadeService } from 'prosumer-app/libs/eyes-core/stores/user/state/user-facade.service';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Directive, inject, Input } from '@angular/core';
import { filterNilValue } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DetailGettingDelegator } from './detail-entities-getter.service';

@Directive({
  selector: '[prosumerDetailEntitiesGetter]',
  standalone: false,
})
@UntilDestroy()
export class DetailEntitiesGetterDirective {
  private readonly delegator = inject(DetailGettingDelegator);
  private readonly _userFacade = inject(UserFacadeService);

  private readonly _step = new BehaviorSubject<ScenarioWizardStep>(undefined);
  @Input() set step(step: ScenarioWizardStep) {
    this._step.next(step);
  }

  private get _userFeatures$() {
    return this._userFacade.clientUserPrefsFeatures$.pipe(
      untilDestroyed(this),
      filterNilValue(),
      take(1),
    );
  }

  constructor() {
    this.subscribeToStepForEntityFetching();
    this.subscribeToGeneralStepForScenarioGenericFetching();
    this.fetchDetailsOnFirstLoadOfPage();
  }

  private fetchDetailsOnFirstLoadOfPage(): void {
    this._userFeatures$.subscribe((userFeatures) => {
      this.delegator.delegateFor(
        this.getDetailsOnFirstLoadOfPage(userFeatures),
      );
    });
  }

  private subscribeToGeneralStepForScenarioGenericFetching(): void {
    this.selectGeneralStep()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.delegator.getScenario());
  }

  private subscribeToStepForEntityFetching(): void {
    this.selectTruthyStep()
      .pipe(untilDestroyed(this))
      .subscribe((step) => this.delegateGettingForStep(step));
  }

  private selectGeneralStep(): Observable<unknown> {
    return this.selectTruthyStep().pipe(
      filter((step) => this.isGeneralStep(step)),
    );
  }

  private isGeneralStep(step: ScenarioWizardStep): boolean {
    return step === ScenarioWizardStep.general;
  }

  private selectTruthyStep(): Observable<ScenarioWizardStep> {
    return this._step.pipe(filterNilValue());
  }

  private delegateGettingForStep(step: ScenarioWizardStep): void {
    this.delegator.delegateFor(this.deduceNeeds(step));
  }

  private deduceNeeds(forStep: ScenarioWizardStep): ScenarioDetailType[] {
    return Coerce.toArray(this.stepNeedsMap[forStep]);
  }

  private get stepNeedsMap(): Partial<
    Record<ScenarioWizardStep, ScenarioDetailType[]>
  > {
    return {
      [ScenarioWizardStep.general]: this.getGeneralNeeds(),
      [ScenarioWizardStep.timeHorizon]: this.getTimeTabNeeds(),
      [ScenarioWizardStep.topology]: this.getTopologyNeeds(),
      [ScenarioWizardStep.loads]: this.getLoadNeeds(),
      [ScenarioWizardStep.equipment]: this.getEquipmentNeeds(),
      [ScenarioWizardStep.commodities]: this.getCommodityNeeds(),
      [ScenarioWizardStep.meters]: this.getMetersNeeds(),
      [ScenarioWizardStep.netting]: this.getNettingNeeds(),
      [ScenarioWizardStep.mobility]: this.getMobilityNeeds(),
      [ScenarioWizardStep.regulation]: this.getRegulationNeeds(),
      [ScenarioWizardStep.frequencyControl]: this.getFrequencyControlNeeds(),
      [ScenarioWizardStep.optimizationCockpit]: this.getCockpitNeeds(),
    };
  }

  private getMetersNeeds(): ScenarioDetailType[] {
    return [
      ScenarioDetailType.meterDefinition,
      ScenarioDetailType.meterCostAndBound,
      ScenarioDetailType.meterTechnologiesAndMarkets,
      ScenarioDetailType.timePartition,
      ScenarioDetailType.variation,
      ScenarioDetailType.energyGridConnection,
    ];
  }

  private getTimeTabNeeds(): ScenarioDetailType[] {
    return [ScenarioDetailType.timePartition];
  }

  private getCockpitNeeds(): ScenarioDetailType[] {
    return [ScenarioDetailType.customOption, ScenarioDetailType.variation];
  }

  private getNettingNeeds(): ScenarioDetailType[] {
    return [
      ScenarioDetailType.netting,
      ScenarioDetailType.taxAndSubsidy,
      ScenarioDetailType.node,
      ScenarioDetailType.energyVector,
      ScenarioDetailType.equipment,
      ScenarioDetailType.load,
      ScenarioDetailType.variation,
    ];
  }

  private getCommodityNeeds(): ScenarioDetailType[] {
    return [
      ScenarioDetailType.energyGridConnection,
      ScenarioDetailType.egcEmission,
      ScenarioDetailType.marketLimit,
      ScenarioDetailType.fuel,
      ScenarioDetailType.node,
      ScenarioDetailType.variation,
    ];
  }

  private getEquipmentNeeds(): ScenarioDetailType[] {
    return [
      ScenarioDetailType.equipment,
      ScenarioDetailType.node,
      ScenarioDetailType.variation,
    ];
  }

  private getLoadNeeds(): ScenarioDetailType[] {
    return [ScenarioDetailType.load, ScenarioDetailType.node];
  }

  private getGeneralNeeds(): ScenarioDetailType[] {
    return [ScenarioDetailType.energyVector, ScenarioDetailType.variation];
  }

  private getTopologyNeeds(): ScenarioDetailType[] {
    return [ScenarioDetailType.node, ScenarioDetailType.line];
  }

  private getMobilityNeeds(): ScenarioDetailType[] {
    return [
      ScenarioDetailType.mobilityRoute,
      ScenarioDetailType.vehiclesDispatch,
      ScenarioDetailType.mobilityStation,
      ScenarioDetailType.node,
      ScenarioDetailType.equipment,
      ScenarioDetailType.variation,
    ];
  }

  private getRegulationNeeds(): ScenarioDetailType[] {
    return [
      ScenarioDetailType.regulation,
      ScenarioDetailType.equipment,
      ScenarioDetailType.variation,
    ];
  }

  private getFrequencyControlNeeds(): ScenarioDetailType[] {
    return [
      ScenarioDetailType.powerNeeded,
      ScenarioDetailType.srMargin,
      ScenarioDetailType.energyVector,
      ScenarioDetailType.equipment,
      ScenarioDetailType.scEquipment,
      ScenarioDetailType.srActivation,
      ScenarioDetailType.reserveEquipment,
      ScenarioDetailType.reserveMarket,
      ScenarioDetailType.reserveNetwork,
      ScenarioDetailType.energyGridConnection,
      ScenarioDetailType.variation,
    ];
  }

  private getDetailsOnFirstLoadOfPage(
    userFeatures: Record<string, number>,
  ): ScenarioDetailType[] {
    return [
      ...this._includeMobilityTabReqs(userFeatures),
      ScenarioDetailType.load,
      ScenarioDetailType.equipment,
    ];
  }

  private _includeMobilityTabReqs(
    userFeatures: Record<string, number>,
  ): ScenarioDetailType[] {
    if (userFeatures['mobility'] == 1)
      return [
        ScenarioDetailType.mobilityRoute,
        ScenarioDetailType.mobilityStation,
        ScenarioDetailType.vehiclesDispatch,
      ];
    return [];
  }
}
